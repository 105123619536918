import React, { useState } from 'react';
import { Button } from '../ui/button';
import { 
  Plus, 
  Trash2, 
  Github, 
  Linkedin, 
  Twitter, 
  Globe, 
  Instagram, 
  Youtube,
  Facebook,
  Mail
} from 'lucide-react';

const SocialLinks = ({ socialLinks = [], onSocialLinksChange }) => {
  const [newLink, setNewLink] = useState({
    platform: '',
    url: '',
    username: ''
  });

  const platforms = [
    { name: 'GitHub', icon: Github },
    { name: 'LinkedIn', icon: Linkedin },
    { name: 'Twitter', icon: Twitter },
    { name: 'Portfolio', icon: Globe },
    { name: 'Instagram', icon: Instagram },
    { name: 'YouTube', icon: Youtube },
    { name: 'Facebook', icon: Facebook },
    { name: 'Email', icon: Mail },
  ];

  const getIcon = (platformName) => {
    const platform = platforms.find(p => p.name.toLowerCase() === platformName.toLowerCase());
    const IconComponent = platform?.icon || Globe;
    return <IconComponent className="w-4 h-4" />;
  };

  const handleAdd = () => {
    if (newLink.platform && newLink.url) {
      onSocialLinksChange([...socialLinks, newLink]);
      setNewLink({
        platform: '',
        url: '',
        username: ''
      });
    }
  };

  const handleDelete = (index) => {
    const updatedLinks = socialLinks.filter((_, i) => i !== index);
    onSocialLinksChange(updatedLinks);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLink(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateUrl = (url) => {
    if (url.startsWith('mailto:')) return true;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const formatUrl = (platform, url) => {
    if (platform.toLowerCase() === 'email' && !url.startsWith('mailto:')) {
      return `mailto:${url}`;
    }
    return url;
  };

  return (
        <div className="space-y-4">
          {socialLinks.map((link, index) => (
            <div key={index} className="p-4 border rounded-lg bg-gray-50">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-3">
                  {getIcon(link.platform)}
                  <div>
                    <h4 className="font-semibold">{link.platform}</h4>
                    <a
                      href={formatUrl(link.platform, link.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-blue-500 hover:text-blue-700"
                    >
                      {link.username || link.url}
                    </a>
                  </div>
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleDelete(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            </div>
          ))}

          <div className="space-y-4 mt-6 border-t pt-4">
            <select
              name="platform"
              className="w-full p-2 border rounded-md"
              value={newLink.platform}
              onChange={handleInputChange}
            >
              <option value="">Select Platform</option>
              {platforms.map((platform) => (
                <option key={platform.name} value={platform.name}>
                  {platform.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="username"
              placeholder="Username or Display Text (optional)"
              className="w-full p-2 border rounded-md"
              value={newLink.username}
              onChange={handleInputChange}
            />
            <input
              type={newLink.platform.toLowerCase() === 'email' ? 'email' : 'url'}
              name="url"
              placeholder={newLink.platform.toLowerCase() === 'email' ? 'Email Address' : 'Profile URL'}
              className="w-full p-2 border rounded-md"
              value={newLink.url}
              onChange={handleInputChange}
            />
            <Button
              onClick={handleAdd}
              className="w-full"
              disabled={!newLink.platform || !newLink.url}
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Social Link
            </Button>
          </div>
        </div>
  );
};

export default SocialLinks;