import React from 'react';

const ModernTemplate = ({ data }) => {
  return (
    <div className="max-w-3xl mx-auto">
      {/* Header Section */}
      <div className="bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 p-16 flex flex-col items-center justify-center">
        <h1 className="text-5xl font-bold text-white mb-2">{data?.basicInfo?.name}</h1>
        <p className="text-2xl text-white/90 font-light">{data?.basicInfo?.title}</p>
      </div>

      {/* About Me Section */}
      <section>
        <h2 className="text-xl font-bold mb-6">About Me</h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          {data?.basicInfo?.about}
        </p>
      </section>

      {/* Skills Section */}
      {data?.basicInfo?.skills && (
        <section>
          <h2 className="text-xl font-bold mb-6">Skills</h2>
          <div className="flex flex-wrap gap-2 justify-center pt-4">
            {data.basicInfo.skills.split(',').map((skill, index) => (
              <span 
                key={index} 
                className="px-3 py-1 bg-gray-100 text-gray-700 rounded-md text-sm"
              >
                {skill.trim()}
              </span>
            ))}
          </div>
        </section>
      )}


      {/* Main Sections */}
      <div className="space-y-12">


        {/* Connect With Me */}
        {data?.socialLinks?.length > 0 && (
          <section>
            <h2 className="text-xl font-bold mb-4">Connect With Me</h2>
            <div className="flex gap-4">
              {data.socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800"
                >
                  {link.platform}
                </a>
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default ModernTemplate;