import React from 'react';

const ProfessionalTemplate = ({ data }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Professional Header with Gradient */}
      <header className="bg-gradient-to-r from-gray-900 to-gray-800 text-white py-12">
        <div className="max-w-5xl mx-auto px-8">
          <h1 className="text-4xl font-bold tracking-tight">{data?.basicInfo?.name}</h1>
          <p className="text-xl text-gray-300 mt-3 font-medium">{data?.basicInfo?.title}</p>
        </div>
      </header>

      <main className="max-w-5xl mx-auto px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
{/* Sidebar */}
<aside className="space-y-8">
  {/* About */}
  <section className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
    <h2 className="text-xl font-bold mb-4 text-gray-900 border-b pb-2">About Me</h2>
    <div className="w-full"> {/* Added container div */}
      <p className="text-gray-700 leading-relaxed">
        {data?.basicInfo?.about}
      </p>
    </div>
  </section>

  {/* Skills */}
  {data?.basicInfo?.skills && (
    <section className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
      <h2 className="text-xl font-bold mb-4 text-gray-900 border-b pb-2">Core Skills</h2>
      <div className="grid grid-cols-2 gap-2">
        {data.basicInfo.skills.split(',').map((skill, index) => (
          <span 
            key={index} 
            className="px-3 py-2 bg-gray-50 text-gray-700 rounded-lg text-sm font-medium hover:bg-gray-100 transition-colors text-center"
          >
            {skill.trim()}
          </span>
        ))}
      </div>
    </section>
  )}


            {/* Social Links */}
            {data?.socialLinks?.length > 0 && (
              <section className="bg-gray-50 p-6 rounded-lg">
                <h2 className="text-xl font-bold mb-4">Connect</h2>
                <div className="space-y-2">
                  {data.socialLinks.map((link, index) => (
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-blue-600 hover:text-blue-800"
                    >
                      {link.platform}
                    </a>
                  ))}
                </div>
              </section>
            )}
          </aside>

          {/* Main Content */}
          <div className="md:col-span-2 space-y-8">
            {/* Experience */}
            {data?.workExperience?.length > 0 && (
              <section>
                <h2 className="text-2xl font-bold mb-6">Professional Experience</h2>
                <div className="space-y-6">
                  {data.workExperience.map((exp, index) => (
                    <div key={index} className="border-l-4 border-gray-900 pl-4">
                      <h3 className="font-bold text-lg">{exp.position}</h3>
                      <p className="text-blue-600">{exp.company}</p>
                      <p className="text-sm text-gray-600">{exp.startDate} - {exp.endDate || 'Present'}</p>
                      <p className="mt-2">{exp.description}</p>
                    </div>
                  ))}
                </div>
              </section>
            )}

            {/* Education */}
            {data?.education?.length > 0 && (
              <section>
                <h2 className="text-2xl font-bold mb-6">Education</h2>
                <div className="space-y-6">
                  {data.education.map((edu, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="font-bold">{edu.school}</h3>
                      <p>{edu.degree} in {edu.field}</p>
                      <p className="text-sm text-gray-600">{edu.startYear} - {edu.endYear || 'Present'}</p>
                      {edu.gpa && <p className="text-sm">GPA: {edu.gpa}</p>}
                      {edu.achievements && <p className="mt-2 text-sm">{edu.achievements}</p>}
                    </div>
                  ))}
                </div>
              </section>
            )}

            {/* Projects */}
            {data?.projects?.length > 0 && (
              <section>
                <h2 className="text-2xl font-bold mb-6">Projects</h2>
                <div className="space-y-6">
                  {data.projects.map((project, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="font-bold">{project.title}</h3>
                      <p className="text-gray-700 mt-2">{project.description}</p>
                      {project.technologies && (
                        <div className="flex flex-wrap gap-2 mt-3">
                          {project.technologies.split(',').map((tech, techIndex) => (
                            <span key={techIndex} className="px-2 py-1 bg-gray-200 rounded text-sm">
                              {tech.trim()}
                            </span>
                          ))}
                        </div>
                      )}
                      {project.link && (
                        <a
                          href={project.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800 mt-2 inline-block"
                        >
                          View Project →
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </section>
            )}

            {/* Certifications */}
            {data?.certifications?.length > 0 && (
              <section>
                <h2 className="text-2xl font-bold mb-6">Certifications</h2>
                <div className="space-y-4">
                  {data.certifications.map((cert, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="font-bold">{cert.name}</h3>
                      <p className="text-gray-600">Issued by {cert.issuer}</p>
                      <p className="text-sm text-gray-500">
                        Issued: {cert.issueDate}
                        {cert.expiryDate && ` • Expires: ${cert.expiryDate}`}
                      </p>
                      {cert.credentialURL && (
                        <a
                          href={cert.credentialURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800 mt-2 inline-block"
                        >
                          View Credential
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </section>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProfessionalTemplate;