import React, { useState } from 'react';
import { europeanCountryPaths, europeanCountries } from '../../maps/europeanPaths';

const CountrySelector = ({ onCountrySelect, selectedCountry }) => {
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setTooltipPosition({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY
    });
  };

  const handleCountryHover = (countryCode) => {
    setHoveredCountry(countryCode ? europeanCountries[countryCode] : null);
  };

  return (
    <div className="space-y-4">
      <p className="text-center text-gray-600 mb-2">
        Select your target country from the map below
      </p>
      
      {/* Removed shadow-sm and adjusted padding */}
      <div className="relative bg-white">
        {hoveredCountry && (
          <div 
            className="absolute bg-white px-2 py-1 rounded shadow-sm text-sm"
            style={{
              left: tooltipPosition.x + 10,
              top: tooltipPosition.y - 10,
              transform: 'translateY(-100%)',
              zIndex: 50
            }}
          >
            {hoveredCountry}
          </div>
        )}
        
        {/* Adjusted container with minimal padding */}
        <div className="border rounded-lg overflow-hidden px-0 py-0">
          <div className="relative w-full aspect-[4/3] sm:aspect-[3/2] md:aspect-[2/1.3]">
            <svg
              viewBox="60 0 1000 670"
              preserveAspectRatio="xMidYMid meet"
              className="absolute inset-0 w-full h-full"
              style={{
                minHeight: '250px',
                touchAction: 'none'
              }}
              onMouseMove={handleMouseMove}
            >
              {/* Adjusted scale and position for better fit */}
              <g transform="translate(0, 0) scale(1)">
                {Object.entries(europeanCountryPaths).map(([code, country]) => {
                  const isSelected = europeanCountries[code] === selectedCountry;
                  return (
                    <path
                      key={code}
                      d={country.path}
                      className={`
                        stroke-black stroke-[0.5] sm:stroke-[0.3] cursor-pointer transition-all duration-200
                        ${isSelected 
                          ? 'fill-blue-500' 
                          : 'fill-white hover:fill-blue-200'
                        }
                      `}
                      style={{ 
                        vectorEffect: 'non-scaling-stroke',
                      }}
                      onClick={() => onCountrySelect(europeanCountries[code])}
                      onMouseEnter={() => handleCountryHover(code)}
                      onMouseLeave={() => handleCountryHover(null)}
                    />
                  );
                })}
              </g>
            </svg>
          </div>
        </div>
      </div>

      {/* Adjusted spacing for dropdown section */}
      <div className="flex flex-col items-center mt-4">
        <p className="text-sm text-gray-500 mb-2">
          Or select from the list:
        </p>
        <select
          id="country-select"
          value={selectedCountry || ''}
          onChange={(e) => onCountrySelect(e.target.value)}
          className="w-full max-w-md p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-base"
        >
          <option value="">Select a country</option>
          {Object.entries(europeanCountries).map(([code, name]) => (
            <option key={code} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {/* Adjusted spacing for selected country indicator */}
      {selectedCountry && (
        <div className="text-center text-base mt-3 bg-blue-50 py-2 px-3 rounded-md">
          <span className="font-medium">Selected: </span>
          <span className="text-blue-600">{selectedCountry}</span>
        </div>
      )}
    </div>
  );
};

export default CountrySelector;