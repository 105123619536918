import React from 'react';
import { Card } from '../ui/card';
import { Input } from '../ui/input';
import { Label } from '../ui/label';

const BasicInformation = ({ 
  currentQuestion, 
  questions, 
  basicInfo, 
  onBasicInfoChange 
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onBasicInfoChange({
      ...basicInfo,
      [name]: value
    });
  };

  const currentQ = questions[currentQuestion];

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {/* Question Progress */}
        <div className="text-sm text-gray-500">
          Question {currentQuestion + 1} of {questions.length}
        </div>

        {/* Question */}
        <div className="space-y-2">
          <Label 
            htmlFor={currentQ.id}
            className="text-lg font-semibold text-gray-900"
          >
            {currentQ.question}
          </Label>
          
          {currentQ.description && (
            <p className="text-sm text-gray-500">
              {currentQ.description}
            </p>
          )}
        </div>

        {/* Input Field */}
        <div className="pt-2">
          {currentQ.type === 'textarea' ? (
            <textarea
              id={currentQ.id}
              name={currentQ.id}
              className="w-full min-h-[120px] p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow resize-y"
              placeholder={currentQ.placeholder}
              value={basicInfo[currentQ.id] || ''}
              onChange={handleInputChange}
            />
          ) : (
            <Input
              id={currentQ.id}
              name={currentQ.id}
              type={currentQ.type || 'text'}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder={currentQ.placeholder}
              value={basicInfo[currentQ.id] || ''}
              onChange={handleInputChange}
            />
          )}
        </div>

        {/* Helper Text */}
        {currentQ.helperText && (
          <p className="text-sm text-gray-500 mt-2">
            {currentQ.helperText}
          </p>
        )}
      </div>

      {/* Preview Card */}
      {Object.keys(basicInfo).length > 0 && (
        <Card className="p-4 bg-gray-50 mt-8">
          <div className="space-y-3">
            <h3 className="text-sm font-medium text-gray-500">Preview</h3>
            <div className="space-y-2">
              {Object.entries(basicInfo).map(([key, value]) => {
                const question = questions.find(q => q.id === key);
                if (!value) return null;
                
                return (
                  <div key={key} className="text-sm">
                    <span className="font-medium text-gray-700">
                      {question?.label || question?.question}:
                    </span>{' '}
                    <span className="text-gray-600">{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default BasicInformation;