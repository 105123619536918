// portfolio-builder\src\components\templates\TemplateGallery.jsx

import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Check } from 'lucide-react';
import { useTemplate } from '../../context/TemplateContext';
import { TEMPLATES } from '../../constants/templates';

const ModernPreview = () => (
  <svg className="w-full h-full" viewBox="0 0 300 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* Header */}
    <rect x="75" y="20" width="150" height="20" rx="2" fill="#E2E8F0"/>
    <rect x="100" y="45" width="100" height="15" rx="2" fill="#CBD5E1"/>
    
    {/* Skills */}
    <rect x="50" y="80" width="40" height="15" rx="2" fill="#EDF2F7"/>
    <rect x="100" y="80" width="40" height="15" rx="2" fill="#EDF2F7"/>
    <rect x="150" y="80" width="40" height="15" rx="2" fill="#EDF2F7"/>
    <rect x="200" y="80" width="40" height="15" rx="2" fill="#EDF2F7"/>
    
    {/* Content Blocks */}
    <rect x="50" y="110" width="200" height="30" rx="2" fill="#F1F5F9"/>
    <rect x="50" y="150" width="200" height="30" rx="2" fill="#F1F5F9"/>
  </svg>
);

const ProfessionalPreview = () => (
  <svg className="w-full h-full" viewBox="0 0 300 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* Dark Header */}
    <rect x="0" y="0" width="300" height="40" fill="#1E293B"/>
    <rect x="20" y="15" width="100" height="10" rx="2" fill="#CBD5E1"/>
    
    {/* Two Column Layout */}
    <rect x="20" y="60" width="80" height="120" rx="2" fill="#F1F5F9"/>
    
    {/* Main Content */}
    <rect x="120" y="60" width="160" height="25" rx="2" fill="#E2E8F0"/>
    <rect x="120" y="95" width="160" height="25" rx="2" fill="#E2E8F0"/>
    <rect x="120" y="130" width="160" height="25" rx="2" fill="#E2E8F0"/>
  </svg>
);

const CreativePreview = () => (
  <svg className="w-full h-full" viewBox="0 0 300 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* Gradient Header */}
    <defs>
      <linearGradient id="gradient" x1="0" y1="0" x2="300" y2="0">
        <stop offset="0%" stopColor="#C084FC"/>
        <stop offset="50%" stopColor="#E879F9"/>
        <stop offset="100%" stopColor="#FB7185"/>
      </linearGradient>
    </defs>
    <rect x="0" y="0" width="300" height="70" fill="url(#gradient)"/>
    <rect x="20" y="40" width="120" height="15" rx="2" fill="#FFFFFF"/>
    
    {/* Creative Content Layout */}
    <rect x="20" y="90" width="260" height="20" rx="2" fill="#F1F5F9"/>
    <circle cx="20" cy="140" r="4" fill="#E879F9"/>
    <rect x="35" y="130" width="245" height="20" rx="2" fill="#F1F5F9"/>
    <circle cx="20" cy="180" r="4" fill="#E879F9"/>
    <rect x="35" y="170" width="245" height="20" rx="2" fill="#F1F5F9"/>
  </svg>
);

const TemplateGallery = () => {
  const { selectedTemplate, setSelectedTemplate } = useTemplate();

  const previewComponents = {
    modern: ModernPreview,
    professional: ProfessionalPreview,
    creative: CreativePreview
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {Object.values(TEMPLATES).map((template) => {
        const PreviewComponent = previewComponents[template.id];
        
        return (
          <Card 
            key={template.id}
            className={`cursor-pointer transition-all ${
              selectedTemplate === template.id ? 'ring-2 ring-blue-500' : ''
            }`}
            onClick={() => setSelectedTemplate(template.id)}
          >
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                {template.name}
                {selectedTemplate === template.id && (
                  <Check className="w-5 h-5 text-blue-500" />
                )}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="aspect-video bg-gray-50 rounded-md mb-4 p-4">
                <PreviewComponent />
              </div>
              <p className="text-sm text-gray-600">{template.description}</p>
            </CardContent>
          </Card>
        )
      })}
    </div>
  );
};

export default TemplateGallery;