// src/components/ui/label.jsx
export function Label({ htmlFor, className, children }) {
    return (
      <label
        htmlFor={htmlFor}
        className={`block text-sm font-medium text-gray-700 ${className}`}
      >
        {children}
      </label>
    );
  }