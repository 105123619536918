import React from 'react';
import { Card } from '../../components/ui/card';
import ModernTemplate from '../templates/ModernTemplate';
import ProfessionalTemplate from '../templates/ProfessionalTemplate';
import CreativeTemplate from '../templates/CreativeTemplate';

const FinalPortfolio = ({ 
  selectedCountry, 
  basicInfo, 
  workExperience, 
  education, 
  projects, 
  certifications, 
  socialLinks,
  selectedTemplate
}) => {
  // Common sections component to be used across all templates
  const CommonSections = () => (
    <div className="space-y-8">
      {/* Work Experience Section */}
      {workExperience.length > 0 && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold border-b pb-2">Work Experience</h3>
          {workExperience.map((exp, index) => (
            <div key={index} className="p-4 border rounded-lg bg-gray-50">
              <div className="space-y-2">
                <h4 className="font-semibold">{exp.company}</h4>
                <p className="text-sm text-gray-600">{exp.position}</p>
                <p className="text-sm text-gray-500">
                  {exp.startDate} - {exp.endDate || 'Present'}
                </p>
                <p className="text-sm mt-2">{exp.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Education Section */}
      {education.length > 0 && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold border-b pb-2">Education</h3>
          {education.map((edu, index) => (
            <div key={index} className="p-4 border rounded-lg bg-gray-50">
              <h4 className="font-semibold">{edu.school}</h4>
              <p>{edu.degree} in {edu.field}</p>
              <p className="text-sm text-gray-600">
                {edu.startYear} - {edu.endYear || 'Present'}
              </p>
              {edu.gpa && <p className="text-sm">GPA: {edu.gpa}</p>}
              {edu.achievements && (
                <p className="text-sm mt-2">{edu.achievements}</p>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Projects Section */}
      {projects.length > 0 && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold border-b pb-2">Projects</h3>
          {projects.map((project, index) => (
            <div key={index} className="p-4 border rounded-lg bg-gray-50">
              <h4 className="font-semibold">{project.title}</h4>
              <p className="text-sm text-gray-600">{project.description}</p>
              {project.technologies && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {project.technologies.split(',').map((tech, techIndex) => (
                    <span key={techIndex} className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                      {tech.trim()}
                    </span>
                  ))}
                </div>
              )}
              {project.link && (
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 text-sm mt-2 inline-block"
                >
                  View Project
                </a>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Certifications Section */}
      {certifications.length > 0 && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold border-b pb-2">Certifications</h3>
          {certifications.map((cert, index) => (
            <div key={index} className="p-4 border rounded-lg bg-gray-50">
              <h4 className="font-semibold">{cert.name}</h4>
              <p className="text-sm text-gray-600">Issued by {cert.issuer}</p>
              <p className="text-sm text-gray-500">
                Issued: {cert.issueDate}
                {cert.expiryDate && ` • Expires: ${cert.expiryDate}`}
              </p>
              {cert.credentialURL && (
                <a
                  href={cert.credentialURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 text-sm mt-1 inline-block"
                >
                  View Credential
                </a>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Social Links Section */}
      {socialLinks.length > 0 && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold border-b pb-2">Connect With Me</h3>
          <div className="flex flex-wrap gap-4">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 text-blue-500 hover:text-blue-700"
              >
                <span>{link.platform}</span>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  // Template wrapper component
  const TemplateWrapper = ({ children }) => (
    <div className="space-y-8">
      {/* Country Badge */}
      {selectedCountry && (
        <div className="inline-block bg-blue-50 text-blue-700 px-4 py-2 rounded-full text-sm font-medium">
          Portfolio for {selectedCountry}
        </div>
      )}
      
      {/* Template-specific header/styling */}
      {children}

      {/* Common sections */}
      <CommonSections />
    </div>
  );

  // Render the selected template or default content
  const renderContent = () => {
    const templateData = {
      basicInfo,
      selectedCountry,
      CommonSections
    };

    switch (selectedTemplate) {
      case 'modern':
        return (
          <TemplateWrapper>
            <ModernTemplate data={templateData} />
          </TemplateWrapper>
        );
      case 'professional':
        return (
          <TemplateWrapper>
            <ProfessionalTemplate data={templateData} />
          </TemplateWrapper>
        );
      case 'creative':
        return (
          <TemplateWrapper>
            <CreativeTemplate data={templateData} />
          </TemplateWrapper>
        );
      default:
        return (
          <div className="space-y-8">
            {/* Country Badge */}
            {selectedCountry && (
              <div className="inline-block bg-blue-50 text-blue-700 px-4 py-2 rounded-full text-sm font-medium">
                Portfolio for {selectedCountry}
              </div>
            )}

            {/* Basic Information */}
            <div className="space-y-4">
              <h2 className="text-2xl font-bold">{basicInfo.name}</h2>
              <p className="text-lg text-gray-600">{basicInfo.title}</p>
              <div className="bg-gray-100 p-4 rounded-md">
                <h3 className="font-semibold">About Me</h3>
                <p>{basicInfo.about}</p>
              </div>
              <div>
                <h3 className="font-semibold">Experience</h3>
                <p>{basicInfo.experience} years</p>
              </div>
              {basicInfo.skills && (
                <div>
                  <h3 className="font-semibold">Skills</h3>
                  <div className="flex flex-wrap gap-2">
                    {basicInfo.skills.split(',').map((skill, index) => (
                      <span key={index} className="bg-blue-100 px-2 py-1 rounded-md">
                        {skill.trim()}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Common Sections */}
            <CommonSections />
          </div>
        );
    }
  };

  return (
    <Card className="w-full">
      {renderContent()}
    </Card>
  );
};

export default FinalPortfolio;