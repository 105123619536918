import React, { useState } from 'react';
import { Button } from '../ui/button';
import { Plus, Trash2 } from 'lucide-react';

const WorkExperience = ({ workExperience = [], onWorkExperienceChange }) => {
  const [newExperience, setNewExperience] = useState({
    company: '',
    position: '',
    startDate: '',
    endDate: '',
    description: ''
  });

  const handleExperienceAdd = () => {
    if (newExperience.company && newExperience.position) {
      onWorkExperienceChange([...workExperience, newExperience]);
      setNewExperience({
        company: '',
        position: '',
        startDate: '',
        endDate: '',
        description: ''
      });
    }
  };

  const handleExperienceDelete = (index) => {
    const updatedExperience = workExperience.filter((_, i) => i !== index);
    onWorkExperienceChange(updatedExperience);
  };

  const handleExperienceInputChange = (e) => {
    const { name, value } = e.target;
    setNewExperience(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="space-y-4">
      {workExperience.map((exp, index) => (
        <div key={index} className="p-4 border rounded-lg bg-gray-50">
          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <h4 className="font-semibold">{exp.company}</h4>
              <p>{exp.position}</p>
              <p className="text-sm text-gray-600">
                {exp.startDate} - {exp.endDate || 'Present'}
              </p>
              <p className="text-sm mt-2">{exp.description}</p>
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={() => handleExperienceDelete(index)}
              className="text-red-500 hover:text-red-700"
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        </div>
      ))}

      <div className="space-y-4 mt-6 border-t pt-4">
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            name="company"
            placeholder="Company Name"
            className="p-2 border rounded-md"
            value={newExperience.company}
            onChange={handleExperienceInputChange}
          />
          <input
            type="text"
            name="position"
            placeholder="Position"
            className="p-2 border rounded-md"
            value={newExperience.position}
            onChange={handleExperienceInputChange}
          />
          <input
            type="text"
            name="startDate"
            placeholder="Start Date"
            className="p-2 border rounded-md"
            value={newExperience.startDate}
            onChange={handleExperienceInputChange}
          />
          <input
            type="text"
            name="endDate"
            placeholder="End Date (or leave blank if current)"
            className="p-2 border rounded-md"
            value={newExperience.endDate}
            onChange={handleExperienceInputChange}
          />
        </div>
        <textarea
          name="description"
          placeholder="Job Description"
          className="w-full p-2 border rounded-md h-24"
          value={newExperience.description}
          onChange={handleExperienceInputChange}
        />
        <Button
          onClick={handleExperienceAdd}
          className="w-full"
          disabled={!newExperience.company || !newExperience.position}
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Work Experience
        </Button>
      </div>
    </div>
  );
};

export default WorkExperience;