import React from 'react';
import './App.css';
import PortfolioBuilder from './PortfolioBuilder';
import { TemplateProvider } from './context/TemplateContext';

function App() {
  return (
    <TemplateProvider>
      <div className="App">
        <PortfolioBuilder />
      </div>
    </TemplateProvider>
  );
}

export default App;