import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './components/ui/card';
import { Button } from './components/ui/button';
import { ChevronRight, ChevronLeft, Check, Wand2 } from 'lucide-react';

// Import components
import Education from './components/sections/Education';
import Projects from './components/sections/Projects';
import Certifications from './components/sections/Certifications';
import SocialLinks from './components/sections/SocialLinks';
import WorkExperience from './components/sections/WorkExperience';
import BasicInformation from './components/sections/BasicInformation';
import CountrySelector from './components/sections/CountrySelector';
import TemplateGallery from './components/templates/TemplateGallery';
import FinalPortfolio from './components/sections/FinalPortfolio';

// Import contexts and constants
import { useTemplate } from './context/TemplateContext';
import { basicInfoQuestions } from './constants/questions';
import { sampleData } from './constants/sampleData';

const sections = [
  { id: 'country', title: 'Select Target Country', component: CountrySelector },
  { id: 'template', title: 'Choose Template', component: TemplateGallery },
  { id: 'basic', title: 'Basic Information', component: BasicInformation },
  { id: 'education', title: 'Education', component: Education },
  { id: 'experience', title: 'Work Experience', component: WorkExperience },
  { id: 'projects', title: 'Projects', component: Projects },
  { id: 'certifications', title: 'Certifications', component: Certifications },
  { id: 'social', title: 'Social Links', component: SocialLinks },
  { id: 'summary', title: 'Portfolio Summary', type: 'summary' }
];

const PortfolioBuilder = () => {
  // State management
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [basicInfo, setBasicInfo] = useState({});
  const [currentBasicQuestion, setCurrentBasicQuestion] = useState(0);
  const [education, setEducation] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [completedSteps, setCompletedSteps] = useState(new Set());

  const { selectedTemplate } = useTemplate();

  const handleAutoFill = () => {
    if (!selectedCountry || currentStep < 2) {
      alert('Please select a country and template first');
      return;
    }
  
    setBasicInfo(sampleData.basicInfo);
    setEducation(sampleData.education);
    setWorkExperience(sampleData.workExperience);
    setProjects(sampleData.projects);
    setCertifications(sampleData.certifications);
    setSocialLinks(sampleData.socialLinks);
    
    const allSections = new Set(sections
      .filter(section => !['country', 'template'].includes(section.id))
      .map(section => section.id)
    );
    setCompletedSteps(prev => new Set([...prev, ...allSections]));
    
    setCurrentStep(sections.length - 1);
    setCurrentBasicQuestion(basicInfoQuestions.length - 1);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    if (!completedSteps.has('country')) {
      setCompletedSteps(prev => new Set([...prev, 'country']));
    }
  };

  const ProgressBar = () => {
    const calculateProgress = () => {
      const totalSteps = sections.length - 1 + basicInfoQuestions.length;
      let currentProgress;
      
      if (!selectedCountry) {
        currentProgress = 0;
      } else if (currentStep < 1) {
        currentProgress = currentStep;
      } else if (sections[currentStep].id === 'basic') {
        currentProgress = currentStep + currentBasicQuestion;
      } else if (sections[currentStep].id === 'summary') {
        currentProgress = totalSteps;
      } else {
        currentProgress = currentStep + basicInfoQuestions.length - 1;
      }
  
      return (currentProgress / totalSteps) * 100;
    };
  
    return (
      <div className="w-full bg-gray-200 h-2 rounded-full mt-4">
        <div 
          className="bg-blue-500 h-2 rounded-full transition-all duration-300"
          style={{ width: `${calculateProgress()}%` }}
        />
      </div>
    );
  };

  const nextStep = () => {
    const currentSection = sections[currentStep];
    
    if (currentSection.id === 'basic') {
      if (currentBasicQuestion < basicInfoQuestions.length - 1) {
        setCurrentBasicQuestion(currentBasicQuestion + 1);
      } else {
        setCurrentStep(currentStep + 1);
        setCurrentBasicQuestion(0);
        setCompletedSteps(prev => new Set([...prev, 'basic']));
      }
    } else {
      setCurrentStep(currentStep + 1);
      setCompletedSteps(prev => new Set([...prev, currentSection.id]));
    }
  };

  const prevStep = () => {
    if (sections[currentStep].id === 'basic' && currentBasicQuestion > 0) {
      setCurrentBasicQuestion(currentBasicQuestion - 1);
    } else {
      setCurrentStep(currentStep - 1);
      if (sections[currentStep - 1]?.id === 'basic') {
        setCurrentBasicQuestion(basicInfoQuestions.length - 1);
      }
    }
  };

  const renderCurrentSection = () => {
    const section = sections[currentStep];
    
    if (section.type === 'summary') {
      return (
        <FinalPortfolio
          selectedCountry={selectedCountry}
          basicInfo={basicInfo}
          workExperience={workExperience}
          education={education}
          projects={projects}
          certifications={certifications}
          socialLinks={socialLinks}
          selectedTemplate={selectedTemplate}
        />
      );
    }
    
    const Section = section.component;
    let props = {};
    
    switch (section.id) {
      case 'country':
        props = {
          onCountrySelect: handleCountrySelect,
          selectedCountry
        };
        break;
      case 'template':
        props = {
          selectedTemplate
        };
        break;
      case 'basic':
        props = {
          currentQuestion: currentBasicQuestion,
          questions: basicInfoQuestions,
          basicInfo,
          onBasicInfoChange: setBasicInfo
        };
        break;
      case 'education':
        props = {
          education,
          onEducationChange: setEducation
        };
        break;
      case 'experience':
        props = {
          workExperience,
          onWorkExperienceChange: setWorkExperience
        };
        break;
      case 'projects':
        props = {
          projects,
          onProjectsChange: setProjects
        };
        break;
      case 'certifications':
        props = {
          certifications,
          onCertificationsChange: setCertifications
        };
        break;
      case 'social':
        props = {
          socialLinks,
          onSocialLinksChange: setSocialLinks
        };
        break;
      default:
        break;
    }
    
    return <Section {...props} />;
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>
            {sections[currentStep].title}
            {selectedCountry && currentStep > 0 && (
              <span className="text-sm text-gray-500 ml-2">
                ({selectedCountry})
              </span>
            )}
          </CardTitle>
          {currentStep > 1 && currentStep < sections.length - 1 && selectedCountry && selectedTemplate && (
            <Button
              variant="outline"
              size="sm"
              onClick={handleAutoFill}
              className="flex items-center gap-2"
            >
              <Wand2 className="w-4 h-4" />
              Auto-fill Remaining Sections
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          {renderCurrentSection()}
          <div className="flex justify-between mt-6">
            {currentStep > 0 && (
              <Button
                variant="outline"
                onClick={prevStep}
                className="flex items-center"
              >
                <ChevronLeft className="w-4 h-4 mr-2" />
                Previous
              </Button>
            )}
            {currentStep < sections.length - 1 && (
              <Button 
                onClick={nextStep}
                disabled={currentStep === 0 && !selectedCountry}
                className={currentStep === 0 ? "ml-auto" : ""}
              >
                {sections[currentStep].id === 'basic' && 
                  currentBasicQuestion === basicInfoQuestions.length - 1 ? (
                  <>
                    Next Section
                    <ChevronRight className="w-4 h-4 ml-2" />
                  </>
                ) : currentStep === sections.length - 2 ? (
                  <>
                    Complete
                    <Check className="w-4 h-4 ml-2" />
                  </>
                ) : (
                  <>
                    {sections[currentStep].id === 'basic' ? 'Next' : 'Next Section'}
                    <ChevronRight className="w-4 h-4 ml-2" />
                  </>
                )}
              </Button>
            )}
          </div>
          <ProgressBar />
        </div>
      </CardContent>
    </Card>
  );
};

export default PortfolioBuilder;