import React, { useState } from 'react';
import { Button } from '../ui/button';
import { Plus, Trash2, ExternalLink } from 'lucide-react';

const Certifications = ({ certifications = [], onCertificationsChange }) => {
  const [newCertification, setNewCertification] = useState({
    name: '',
    issuer: '',
    issueDate: '',
    expiryDate: '',
    credentialId: '',
    credentialURL: ''
  });

  // Add validation
  const isValidInput = () => {
    return (
      newCertification.name.trim() !== '' && 
      newCertification.issuer.trim() !== '' &&
      (newCertification.credentialURL === '' || isValidURL(newCertification.credentialURL))
    );
  };

  // URL validation helper
  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleAdd = () => {
    if (isValidInput()) {
      // Create a new object to avoid reference issues
      const certificationToAdd = {
        ...newCertification,
        name: newCertification.name.trim(),
        issuer: newCertification.issuer.trim(),
        dateAdded: new Date().toISOString() // Add creation date
      };

      onCertificationsChange([...certifications, certificationToAdd]);
      
      // Reset form
      setNewCertification({
        name: '',
        issuer: '',
        issueDate: '',
        expiryDate: '',
        credentialId: '',
        credentialURL: ''
      });
    }
  };

  const handleDelete = (index) => {
    // Add confirmation before deleting
    if (window.confirm('Are you sure you want to delete this certification?')) {
      const updatedCertifications = certifications.filter((_, i) => i !== index);
      onCertificationsChange(updatedCertifications);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCertification(prev => ({
      ...prev,
      [name]: value.trim() // Trim whitespace as user types
    }));
  };

  // Add keyboard support
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isValidInput()) {
      handleAdd();
    }
  };

  // Rest of your component remains the same...
  return (
        <div className="space-y-4">
          {certifications.map((cert, index) => (
            <div key={index} className="p-4 border rounded-lg bg-gray-50">
              <div className="flex justify-between items-start">
                <div className="space-y-1">
                  <h4 className="font-semibold">{cert.name}</h4>
                  <p className="text-sm text-gray-600">Issued by {cert.issuer}</p>
                  <p className="text-sm text-gray-500">
                    Issued: {cert.issueDate}
                    {cert.expiryDate && ` • Expires: ${cert.expiryDate}`}
                  </p>
                  {cert.credentialId && (
                    <p className="text-sm">Credential ID: {cert.credentialId}</p>
                  )}
                  {cert.credentialURL && (
                    <a
                      href={cert.credentialURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700 inline-flex items-center text-sm mt-1"
                    >
                      View Credential
                      <ExternalLink className="w-3 h-3 ml-1" />
                    </a>
                  )}
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleDelete(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            </div>
          ))}

          <div className="space-y-4 mt-6 border-t pt-4">
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="name"
                placeholder="Certification Name"
                className="p-2 border rounded-md"
                value={newCertification.name}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="issuer"
                placeholder="Issuing Organization"
                className="p-2 border rounded-md"
                value={newCertification.issuer}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="issueDate"
                placeholder="Issue Date"
                className="p-2 border rounded-md"
                value={newCertification.issueDate}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="expiryDate"
                placeholder="Expiry Date (if applicable)"
                className="p-2 border rounded-md"
                value={newCertification.expiryDate}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="credentialId"
                placeholder="Credential ID"
                className="p-2 border rounded-md"
                value={newCertification.credentialId}
                onChange={handleInputChange}
              />
              <input
                type="url"
                name="credentialURL"
                placeholder="Credential URL"
                className="p-2 border rounded-md"
                value={newCertification.credentialURL}
                onChange={handleInputChange}
                />
            </div>
            <Button
              onClick={handleAdd}
              className="w-full"
              disabled={!newCertification.name || !newCertification.issuer}
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Certification
            </Button>
          </div>
        </div>
  );
};

export default Certifications;