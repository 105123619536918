
// portfolio-builder\src\constants\sampleData.js
export const sampleData = {
    country: 'Poland',
    template: 'professional',
    basicInfo: {
      name: 'Jan Kowalski',
      title: 'Senior Software Developer',
      about: 'Experienced software developer with a passion for creating efficient, scalable solutions. Specializing in full-stack development with modern technologies.',
      experience: '8',
      skills: 'JavaScript, React, Node.js, Python, AWS, Docker, TypeScript, GraphQL',
      email: 'jan.kowalski@example.com',
      phone: '+48 123 456 789',
      location: 'Warsaw, Poland'
    },
    education: [
      {
        school: 'Warsaw University of Technology',
        degree: 'Master of Science',
        field: 'Computer Science',
        startYear: '2012',
        endYear: '2014',
        gpa: '3.8',
        achievements: 'Graduated with honors, Research Assistant in AI Lab'
      },
      {
        school: 'University of Warsaw',
        degree: 'Bachelor',
        field: 'Software Engineering',
        startYear: '2008',
        endYear: '2012',
        gpa: '3.9',
        achievements: 'Dean\'s List all semesters'
      }
    ],
    workExperience: [
      {
        company: 'Tech Solutions Poland',
        position: 'Senior Software Developer',
        startDate: '2019',
        endDate: 'Present',
        description: 'Lead developer for enterprise-scale React applications. Mentored junior developers and implemented CI/CD pipelines.'
      },
      {
        company: 'Digital Innovation Labs',
        position: 'Full Stack Developer',
        startDate: '2015',
        endDate: '2019',
        description: 'Developed and maintained multiple web applications using React and Node.js. Improved system performance by 40%.'
      }
    ],
    projects: [
      {
        title: 'E-commerce Platform',
        description: 'Built a scalable e-commerce platform handling 10k+ daily users',
        technologies: 'React, Node.js, MongoDB, AWS',
        link: 'https://github.com/example/ecommerce'
      },
      {
        title: 'AI-Powered Analytics Dashboard',
        description: 'Developed a real-time analytics dashboard with machine learning capabilities',
        technologies: 'Python, TensorFlow, React, D3.js',
        link: 'https://github.com/example/analytics'
      }
    ],
    certifications: [
      {
        name: 'AWS Certified Solutions Architect',
        issuer: 'Amazon Web Services',
        issueDate: '2023',
        expiryDate: '2026',
        credentialURL: 'https://aws.amazon.com/certification'
      },
      {
        name: 'Professional Scrum Master I',
        issuer: 'Scrum.org',
        issueDate: '2022',
        credentialURL: 'https://www.scrum.org/certificates'
      }
    ],
    socialLinks: [
      {
        platform: 'LinkedIn',
        url: 'https://linkedin.com/in/jankowalski'
      },
      {
        platform: 'GitHub',
        url: 'https://github.com/jankowalski'
      },
      {
        platform: 'Portfolio',
        url: 'https://jankowalski.dev'
      }
    ]
  };