import React from 'react';

const CreativeTemplate = ({ data }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white">
      {/* Creative Header with Dynamic Gradient */}
      <header className="relative h-96 bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-20 backdrop-blur-sm" />
        {/* Abstract Shapes */}
        <div className="absolute inset-0">
          <div className="absolute top-20 left-20 w-40 h-40 bg-white opacity-10 rounded-full blur-xl" />
          <div className="absolute bottom-10 right-20 w-60 h-60 bg-purple-300 opacity-10 rounded-full blur-xl" />
        </div>
        <div className="absolute bottom-0 left-0 right-0 p-12 bg-gradient-to-t from-black/30">
          <div className="max-w-5xl mx-auto">
            <h1 className="text-6xl font-bold mb-4 text-white tracking-tight">{data?.basicInfo?.name}</h1>
            <p className="text-2xl text-gray-100 font-light">{data?.basicInfo?.title}</p>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-5xl mx-auto p-8 space-y-20 relative">
        {/* About Section */}
        <section className="relative bg-white rounded-2xl p-8 shadow-xl">
          <div className="absolute -left-1 top-8 bottom-8 w-1 bg-gradient-to-b from-purple-500 to-pink-500 rounded-full" />
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">About Me</h2>
          <p className="text-lg text-gray-700 leading-relaxed">{data?.basicInfo?.about}</p>
        </section>

        {/* Skills */}
        {data?.basicInfo?.skills && (
          <section className="bg-white rounded-2xl p-8 shadow-xl">
            <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">Skills</h2>
            <div className="flex flex-wrap gap-3">
              {data.basicInfo.skills.split(',').map((skill, index) => (
                <span
                  key={index}
                  className="px-6 py-2.5 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full font-medium hover:from-purple-600 hover:to-pink-600 transition-all duration-300 shadow-md hover:shadow-lg cursor-default"
                >
                  {skill.trim()}
                </span>
              ))}
            </div>
          </section>
        )}

        {/* Experience */}
        {data?.workExperience?.length > 0 && (
          <section className="bg-white rounded-2xl p-8 shadow-xl">
            <h2 className="text-3xl font-bold mb-12 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">Experience</h2>
            <div className="space-y-12">
              {data.workExperience.map((exp, index) => (
                <div key={index} className="relative group">
                  <div className="absolute -left-4 top-0 h-5 w-5 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 shadow-md group-hover:scale-110 transition-transform duration-300" />
                  <div className="pl-8">
                    <h3 className="text-2xl font-bold text-gray-800 group-hover:text-purple-600 transition-colors">
                      {exp.position}
                    </h3>
                    <p className="text-xl bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
                      {exp.company}
                    </p>
                    <p className="text-gray-500 mb-4 font-light">{exp.startDate} - {exp.endDate || 'Present'}</p>
                    <p className="text-gray-700 leading-relaxed">{exp.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        {/* Projects Section */}
        {data?.projects?.length > 0 && (
          <section>
            <h2 className="text-3xl font-bold mb-8">Projects</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {data.projects.map((project, index) => (
                <div key={index} className="group relative overflow-hidden rounded-lg shadow-lg">
                  <div className="p-6 bg-gradient-to-br from-purple-100 to-pink-100">
                    <h3 className="text-xl font-bold text-gray-800">{project.title}</h3>
                    <p className="text-gray-600 mt-2">{project.description}</p>
                    {project.technologies && (
                      <div className="mt-4 flex flex-wrap gap-2">
                        {project.technologies.split(',').map((tech, techIndex) => (
                          <span key={techIndex} className="px-2 py-1 bg-white rounded text-sm text-purple-600">
                            {tech.trim()}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default CreativeTemplate;