export const TEMPLATES = {
    modern: {
      id: 'modern',
      name: 'Modern',
      description: 'Clean and minimalist design with focus on readability',
      preview: '/templates/modern-preview.png'
    },
    professional: {
      id: 'professional',
      name: 'Professional',
      description: 'Traditional layout perfect for corporate environments',
      preview: '/templates/professional-preview.png'
    },
    creative: {
      id: 'creative',
      name: 'Creative',
      description: 'Bold design for creative professionals',
      preview: '/templates/creative-preview.png'
    }
  };