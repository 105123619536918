import React, { useState } from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Plus, X } from 'lucide-react';

const Projects = ({ projects = [], onProjectsChange }) => {
  const [newProject, setNewProject] = useState({
    title: '',
    description: '',
    technologies: '',
    link: ''
  });

  const handleAddProject = () => {
    if (newProject.title && newProject.description) {
      onProjectsChange([...projects, { ...newProject }]);
      setNewProject({
        title: '',
        description: '',
        technologies: '',
        link: ''
      });
    }
  };

  const handleRemoveProject = (index) => {
    const updatedProjects = projects.filter((_, i) => i !== index);
    onProjectsChange(updatedProjects);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="space-y-6">
      {/* Existing Projects List */}
      {projects.map((project, index) => (
        <Card key={index} className="relative">
          <Button
            variant="ghost"
            size="icon"
            className="absolute top-2 right-2"
            onClick={() => handleRemoveProject(index)}
          >
            <X className="h-4 w-4" />
          </Button>
          <CardContent className="pt-6">
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold">{project.title}</h3>
                <p className="text-sm text-gray-600">{project.description}</p>
              </div>
              {project.technologies && (
                <div className="flex flex-wrap gap-2">
                  {project.technologies.split(',').map((tech, techIndex) => (
                    <span
                      key={techIndex}
                      className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full"
                    >
                      {tech.trim()}
                    </span>
                  ))}
                </div>
              )}
              {project.link && (
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 text-sm inline-block"
                >
                  View Project
                </a>
              )}
            </div>
          </CardContent>
        </Card>
      ))}

      {/* Add New Project Form */}
      <Card>
        <CardContent className="pt-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Project Title</label>
              <input
                type="text"
                name="title"
                value={newProject.title}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter project title"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Description</label>
              <textarea
                name="description"
                value={newProject.description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                rows="3"
                placeholder="Describe your project"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Technologies Used</label>
              <input
                type="text"
                name="technologies"
                value={newProject.technologies}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="e.g., React, Node.js, MongoDB (comma-separated)"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Project Link (Optional)</label>
              <input
                type="url"
                name="link"
                value={newProject.link}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="https://..."
              />
            </div>

            <Button
              onClick={handleAddProject}
              disabled={!newProject.title || !newProject.description}
              className="w-full flex items-center justify-center gap-2"
            >
              <Plus className="w-4 h-4" />
              Add Project
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Projects;