import React, { useState } from 'react';
import { Button } from '../ui/button';
import { Plus, Trash2 } from 'lucide-react';

const Education = ({ education = [], onEducationChange }) => {
  const [newEducation, setNewEducation] = useState({
    school: '',
    degree: '',
    field: '',
    startYear: '',
    endYear: '',
    gpa: '',
    achievements: ''
  });

  const handleAdd = () => {
    if (newEducation.school && newEducation.degree) {
      onEducationChange([...education, newEducation]);
      setNewEducation({
        school: '',
        degree: '',
        field: '',
        startYear: '',
        endYear: '',
        gpa: '',
        achievements: ''
      });
    }
  };

  const handleDelete = (index) => {
    const updatedEducation = education.filter((_, i) => i !== index);
    onEducationChange(updatedEducation);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEducation(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="space-y-4">
      {education.map((edu, index) => (
        <div key={index} className="p-4 border rounded-lg bg-gray-50">
          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <h4 className="font-semibold">{edu.school}</h4>
              <p>{edu.degree} in {edu.field}</p>
              <p className="text-sm text-gray-600">
                {edu.startYear} - {edu.endYear || 'Present'}
              </p>
              {edu.gpa && <p className="text-sm">GPA: {edu.gpa}</p>}
              {edu.achievements && (
                <p className="text-sm mt-2">{edu.achievements}</p>
              )}
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={() => handleDelete(index)}
              className="text-red-500 hover:text-red-700"
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        </div>
      ))}

      <div className="space-y-4 mt-6 border-t pt-4">
        <div className="grid grid-cols-2 gap-4">
          <input
            type="text"
            name="school"
            placeholder="Institution Name"
            className="p-2 border rounded-md"
            value={newEducation.school}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="degree"
            placeholder="Degree"
            className="p-2 border rounded-md"
            value={newEducation.degree}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="field"
            placeholder="Field of Study"
            className="p-2 border rounded-md"
            value={newEducation.field}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="gpa"
            placeholder="GPA (optional)"
            className="p-2 border rounded-md"
            value={newEducation.gpa}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="startYear"
            placeholder="Start Year"
            className="p-2 border rounded-md"
            value={newEducation.startYear}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="endYear"
            placeholder="End Year (or expected)"
            className="p-2 border rounded-md"
            value={newEducation.endYear}
            onChange={handleInputChange}
          />
        </div>
        <textarea
          name="achievements"
          placeholder="Notable achievements, activities, or honors (optional)"
          className="w-full p-2 border rounded-md h-24"
          value={newEducation.achievements}
          onChange={handleInputChange}
        />
        <Button
          onClick={handleAdd}
          className="w-full"
          disabled={!newEducation.school || !newEducation.degree}
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Education
        </Button>
      </div>
    </div>
  );
};

export default Education;