export const basicInfoQuestions = [
  {
    id: 'name',
    question: "What's your full name?",
    label: 'Full Name',
    type: 'text',
    placeholder: 'e.g., John Doe',
    helperText: 'Enter your name as you would like it to appear on your portfolio'
  },
  {
    id: 'title',
    question: 'What is your professional title?',
    label: 'Professional Title',
    type: 'text',
    placeholder: 'e.g., Senior Software Developer',
    helperText: 'Your current or desired job title'
  },
  {
    id: 'about',
    question: 'Tell us about yourself',
    label: 'About',
    type: 'textarea',
    placeholder: 'Write a brief professional summary...',
    helperText: 'A short bio highlighting your key expertise and career focus'
  },
  {
    id: 'experience',
    question: 'How many years of experience do you have?',
    label: 'Years of Experience',
    type: 'number',
    placeholder: 'e.g., 5',
    helperText: 'Total years of relevant professional experience'
  },
  {
    id: 'skills',
    question: 'What are your key skills?',
    label: 'Skills',
    type: 'text',
    placeholder: 'e.g., React, Node.js, Python (comma-separated)',
    helperText: 'List your main technical skills, separated by commas'
  }
];